import React from 'react';
import './VideoGrid.scss';
import YoutubeEmbed from '../YoutubeEmbed';

export function VideoGrid(props) {
  return (
    <React.Fragment>
      <div className='video-grid'>
        <YoutubeEmbed embedId="gUTfTqO-px0" />
        <YoutubeEmbed embedId="Xh1PeexfFkw" />
        <YoutubeEmbed embedId="OTYfnYiErkc" />
        <YoutubeEmbed embedId="Fy__Et-BHjc" />
        <YoutubeEmbed embedId="cqhhfYD5XXo" />
        <YoutubeEmbed embedId="qkRgHiKFj84" />
        <YoutubeEmbed embedId="_wnbv4iUO2Y" />
        <YoutubeEmbed embedId="5OjFCh1Oi_I" />              
      </div>
    </React.Fragment>
  );
}
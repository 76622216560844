import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import Gallery from '../components/Gallery';
import { VideoGrid } from '../components/VideoGrid/VideoGrid';

const img_set = [
  {
    src: require('../assets/images/portfolio/fullsize/1.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/1.jpg'),
    title: 'Category',
    desc: 'Project Name',
  },
  {
    src: require('../assets/images/portfolio/fullsize/2.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/2.jpg'),
    title: 'Category',
    desc: 'Project Name',
  },
  {
    src: require('../assets/images/portfolio/fullsize/3.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/3.jpg'),
    title: 'Category',
    desc: 'Project Name',
  },
  {
    src: require('../assets/images/portfolio/fullsize/4.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/4.jpg'),
    title: 'Category',
    desc: 'Project Name',
  },
  {
    src: require('../assets/images/portfolio/fullsize/5.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/5.jpg'),
    title: 'Category',
    desc: 'Project Name',
  },
  {
    src: require('../assets/images/portfolio/fullsize/6.jpg'),
    thumbnail: require('../assets/images/portfolio/thumbnails/6.jpg'),
    title: 'Category',
    desc: 'Project Name',
  },
];
const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <h1 className="text-uppercase text-white font-weight-bold">
              {config.heading}
            </h1>
            <hr className="divider my-4" />
          </div>
          <div className="col-lg-8 align-self-baseline">
            <p className="text-white-75 font-weight-light mb-5">
              {config.subHeading}
            </p>
            <Scroll type="id" element="about">
              <a
                className="btn btn-primary btn-xl js-scroll-trigger"
                href="#about"
              >
                Find Out More
              </a>
            </Scroll>
          </div>
        </div>
      </div>
    </header>

    <section className="page-section bg-primary" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="text-white mt-0">About</h2>
            <hr className="divider light my-4" />
            <p className="text-white-50 mb-4">
              Dr. M Viswanatha Raju, aged 69, hails from Hyderabad and is a Gold Medialist in M.A Astrology and Ph.D in Vastu. He has been consulting 
              for more than 30 years all over the world. He is a voracious reader, multi linguistic and has written over 20 books in telugu related
              to these fields. He has been conferred many awards and titles including the below most prestigious titles.
              <li>Gold Medal in Astrology by Former A.P. Governor, Shri Shushilkumar Shinde</li>
              <li>Vaastu Samrat by Akhila Bharatha Jyotisha Sammelan</li>
              <li>Jyotisha Visarada by Gayathri Jyotisha Vignana Samstha</li>    
              <li>Daivajna Siromani by Vastu Vidya Vijnana Kendram</li>            
            </p>
            <Scroll type="id" element="services">
              <a
                className="btn btn-light btn-xl js-scroll-trigger"
                href="#services"
              >
                Get Started!
              </a>
            </Scroll>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section" id="services">
      <div className="container">
        <h2 className="text-center mt-0">Services</h2>
        <hr className="divider my-4" />
        <div className="row">
          <div className="col-lg-3 col-md-6 text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-gem text-primary mb-4"></i>
              <h3 className="h4 mb-2">Astrology</h3>
              <p className="text-muted mb-0">
                Detailed horoscope reading and writing,match making and how each planet shapes your mind, body and material life!
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-laptop-code text-primary mb-4"></i>
              <h3 className="h4 mb-2">Vastu</h3>
              <p className="text-muted mb-0">
                Build your dream house based on vastu design, your ideas and requirements.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-globe text-primary mb-4"></i>
              <h3 className="h4 mb-2">Remedies</h3>
              <p className="text-muted mb-0">
                Astrological or gemstone remedies to help balance planets' energies in a horoscope. 
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <div className="mt-5">
              <i className="fas fa-4x fa-heart text-primary mb-4"></i>
              <h3 className="h4 mb-2">Muhurtam</h3>
              <p className="text-muted mb-0">
                The 'right time' for the initiation of an important process/event that has the power to change destiny like marriage, gruhapravesham, gruha aarambam, upanayam and all related ceremonies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section" id="videos">
    <h2 className="text-center mt-0">Portfolio</h2>
    <hr className="divider my-4" />
    <div className="responsive-video-grid-container">
      <VideoGrid id="PortFolio">       
      </VideoGrid>
      </div>
    </section>
    <section className="page-section bg-primary" id="testimonials">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="text-white mt-0">Client Testimonials</h2>
            <hr className="divider light my-4" />
            <p className="text-white-50 mb-4">
              "Raju garu gave us a very detailed reading of my son's horoscope and gave us very good suggestions on his career and marriage plans. -- Anonymous"
            </p>
          </div>
        </div>
      </div>
    </section>

    <ContactUs />

    <Footer />
  </Layout>
);

export default IndexPage;
